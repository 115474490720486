import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, styled } from "@material-ui/core";

interface Props {
    message: string,
    open: boolean,
    onClose: (confirmed: boolean) => void,
}

export function ConfirmationDialog({message, open, onClose}: Props) {
    return <Dialog open={open} onClose={() => onClose(false)}>
        <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose(false)}>
                Abbrechen
            </Button>
            <DeleteButton color="primary" onClick={() => onClose(true)}>
                Löschen
            </DeleteButton>
        </DialogActions>
    </Dialog>
}

const DeleteButton = styled(Button)(({theme}) => ({
    color: theme.palette.error.main,
}))