import React, { useState } from "react";
import { Box, Icon, IconButton, styled, Theme, Typography, useTheme } from "@material-ui/core";
import { FunctionData } from "../../api/Model";
import { DisabledPrimaryIconRound, PrimaryIconRound } from "../Styleguide/Icon";

const spacingY = 0.75

export interface LegendProps {
    inSelection: boolean
}
export function Legend({ inSelection }: LegendProps)  {
    const theme = useTheme();

    return (
        <LegendContainer inSelection={inSelection}>
            <TextBox>
                <Typography variant={"body1"} style={{ marginBottom: 0, fontWeight: 900, color: theme.palette.black100.main }}>Legende:</Typography>
            </TextBox>
            <LegendColorBox backgroundColor={theme.palette.customer.main}>
                <Typography variant={"body1"} style={{ marginBottom: 0, fontWeight: 900, color: theme.palette.black100.main }}>Kundin/Kunde</Typography>
            </LegendColorBox>
            <LegendColorBox backgroundColor={theme.palette.administration.main}>
                <Typography variant={"body1"} style={{ marginBottom: 0, fontWeight: 900, color: theme.palette.black100.main }}>Verwaltung</Typography>
            </LegendColorBox>
        </LegendContainer>
    )
}

const TextBox = styled(Box)({
    flexShrink: 1,
    display: "flex",
    alignItems: "center",
    margin: 0,
    paddingRight: "5px"
})

const LegendContainer = styled(({inSelection, ...rest}) => <Box {...rest}/>)
(({theme, inSelection}: {theme: Theme, inSelection: Boolean}) => ({
    display: "flex",
    paddingTop: inSelection ? "" : "15px",
    paddingBottom: "15px",
    [`@media (min-width:${theme.breakpoints.values.legend}px)`]: {
        flexDirection: "row",
    },
    [`@media (max-width:${theme.breakpoints.values.legend - 1}px)`]: {
        flexDirection: "column",
    },
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "24px",
}))

export const LegendColorBox = styled(({theme, backgroundColor, ...rest}) => <Box {...rest}/>)
(({theme, backgroundColor} : { theme: Theme, backgroundColor: string }) => ({
    flexShrink: 9999,
    padding: "5px",
    maxWidth: "180px",
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    textAlign: "center",
    backgroundColor: backgroundColor,
    '@media print': {
        boxShadow: `inset 0 0 0 1000px ${backgroundColor}`,
    },
    [`@media (min-width:${theme.breakpoints.values.legend}px)`]: {
        marginRight: "5px",
    },
    [`@media (max-width:${theme.breakpoints.values.legend - 1}px)`]: {
        marginTop: "5px",
    },
}))

interface FunctionHeaderProps {
    functionData: FunctionData,
    type: "customer" | "administration",
}

export const FunctionHeader = React.forwardRef<HTMLDivElement, FunctionHeaderProps>((props: FunctionHeaderProps, ref) => {
    const theme = useTheme();
    const [details, setDetails] = useState(false)

    const isCustomer = props.type === "customer"
    const backGroundColor = isCustomer ? theme.palette.customer.main : theme.palette.administration.main;
    const isNotEmpty = props.functionData.enabled;
    const description = props.functionData.description

     return (
        <FunctionContainer>
            <div ref={ref} style={{ display: 'flex' }}>
                <FunctionHeaderColorBox backgroundColor={backGroundColor} empty={!isNotEmpty}>
                    <IconBox>
                        <Icon/>
                    </IconBox>
                    <TitleBox seeDetails={details}>
                        <Typography variant={"body2"} style={{marginBottom: 0}}>
                            {props.functionData.name}
                        </Typography>
                    </TitleBox>
                    <IconBox>
                        {isNotEmpty && <DescriptionButton onClick={e => {
                            e.stopPropagation()
                            setDetails(old => !old)
                        }}
                                                          disabled={!description}>
                            {!description ?
                                <DisabledPrimaryIconRound>{details ? "close" : "info"}</DisabledPrimaryIconRound> :
                                <PrimaryIconRound>{details ? "close" : "info"}</PrimaryIconRound>}
                        </DescriptionButton>}
                    </IconBox>
                </FunctionHeaderColorBox>
            </div>
            <DescriptionBox style={{display: details ? "flex" : "none"}}>
                {props.functionData.enabled &&
                    <DescriptionContainer>
                        <Typography variant={"body1"} style={{marginBottom: 0}}>
                            {props.functionData.description}
                        </Typography>
                    </DescriptionContainer>
                }
            </DescriptionBox>
        </FunctionContainer>
    )
})

const DescriptionContainer = styled(Box)({
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",

    margin: 0,
    padding: 6,
    textAlign: "left",
    wordBreak: "break-word",
    '@media print': {
        display: 'none',
    }
})

const DescriptionBox = styled(Box)({
    display: "flex",
    '@media print': {
        display: 'flex',
    }
})

const DescriptionButton = styled(IconButton)({
    padding: 0,
    height: '35px',
    width: '35px'
})

export interface ColorBoxProps {
    backgroundColor: string,
    empty: boolean,
}

const FunctionContainer = styled('div')({
    display: "flex",
    flexDirection: "column"
})

export const FunctionHeaderColorBox = styled(({backgroundColor, empty, ...rest}) => <Box {...rest}/>)
((props: { theme: Theme } & ColorBoxProps) => ({
    height: props.empty ? 0 : 'auto',
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    textAlign: "center",
    padding: props.empty ? 0 : props.theme.spacing(spacingY, 0),
    backgroundColor: props.empty ? undefined : props.backgroundColor,
    '@media print': {
        boxShadow: !props.empty ? `inset 0 0 0 1000px ${props.backgroundColor}` : '',
        display: !props.empty ? 'inline-flex' : 'none',
    }
}))

const IconBox = styled(Box)({
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "10px",
    '@media print': {
        display: 'none',
    },
})

const TitleBox = styled(({seeDetails, ...rest}) => <Box {...rest}/>)(() => ({
    flexGrow: 2,
    flexShrink: 2,
    width: "175px",
    '@media print': {
        fontWeight: 500,
    },
    '& > *':{
        whiteSpace: "normal",
        wordWrap: "break-word",
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))
