export interface InstanceRoutes {
    functions: (step?: number) => string,
    summary: string,
}

export const routes = {
    admin: {
        exportOverview: () => `/api/v1/private/export/overview`,
        functions: {
            root: () => `/admin/functions`,
            create: (step?: number) => `/admin/functions/create/${step ?? ':step'}`,
            edit: (id?: string) => `/admin/functions/edit/${id ?? ':id'}`,
            list: (step?: number) => `/admin/functions/${step ?? ':step'}`,
        },
        instances: () => `/admin/instances`,
        resources: () => `/admin/resources`,
    },
    public: {
        login: () => `/login`,
        impressum: () => `/impressum`,
        notAvailable: () => `/not-available`,
        sharing: () => '/sharing/:id',
        instance: (id?: string) => ({
            functions: (step?: number) => `/instances/${id ?? ':id'}/functions/${step ?? ':step'}`,
            summary: `/instances/${id ?? ':id'}/summary`,
        }),
    },
}
