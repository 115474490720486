import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { useHref } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ReactComponent as CloseButton } from "../../images/icons/close.svg";
import { HeaderIconBox } from "../Containers";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    styled,
} from "@material-ui/core";
import { ButtonLGPrimary, ButtonLGSecondary } from "../Styleguide/Button";

interface DialogProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
}

export function PersonalLinkDialog(props: DialogProps) {
    const snackbar = useSnackbar()
    const closeDialog = () => props.setOpen(false);
    const linkRef = useRef<HTMLInputElement>(null);
    const [width, setWidth] = useState(0);
    const path = useHref(".");

    const host = window.location.host;
    const editingLink = host + path;

    const measuredRef = useCallback((node: HTMLButtonElement) => {
        if (node !== null) {
            setWidth(node.getBoundingClientRect().width);
        }
    }, []);

    return (
        <Dialog open={props.open}>
            <DialogHeader>
                <StyledDialogTitle>Persönlicher Link zum Projekt</StyledDialogTitle>
                <DialogIconBox onClick={closeDialog}>
                    <CloseButton />
                </DialogIconBox>
            </DialogHeader>
            <StyledDialogTextContent>
                <DialogContentText>
                    Mit folgendem Link können Sie den letzten Stand Ihrer Eingaben jederzeit in einem Webbrowser
                    aufrufen
                    und weiter bearbeiten. Bewahren Sie den Link gut auf oder fügen Sie diese Seite zu Ihren Favoriten
                    hinzu.
                </DialogContentText>
            </StyledDialogTextContent>
            <StyledDialogLinkContent>
                <Box display="flex" marginBottom={0}>
                    <LinkTextField ref={linkRef} onFocus={e => e.target.select()} readOnly
                                   value={editingLink}/>
                    <CopyButton ref={measuredRef} variant="contained" startIcon={<Icon style={{ marginLeft: 0 }}>content_copy</Icon>}
                                  onClick={() => {
                                      if (navigator.clipboard) {
                                          navigator.clipboard.writeText(editingLink)
                                              .then(() => {
                                                  snackbar.enqueueSnackbar("Link in die Zwischenablage kopiert", {variant: "success"})
                                              })
                                      } else {
                                          linkRef.current?.select()
                                          if (document.execCommand("copy")) {
                                              snackbar.enqueueSnackbar("Link in die Zwischenablage kopiert", {variant: "success"})
                                          }
                                      }
                                  }}>
                        <ButtonText>Kopieren</ButtonText>
                    </CopyButton>
                </Box>
            </StyledDialogLinkContent>
            <StyledDialogActions>
                <StyledContinueButton key={width} width={width !== undefined ? width.toString( ) + "px" : "48px"} onClick={closeDialog} variant="contained">Schliessen</StyledContinueButton>
            </StyledDialogActions>
        </Dialog>
    )
}

export const DialogHeader = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
})

export const StyledDialogTitle = styled(DialogTitle)(({theme}) => ({
    flexShrink: 2,
    [`@media (max-width:${theme.breakpoints.values.dialogLayout - 1}px)`]: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "8px",
        paddingBottom: "8px",
    },
}))

export const DialogIconBox = styled(HeaderIconBox) (({theme}) => ({
    padding: "16px 24px",
    [`@media (min-width:${theme.breakpoints.values.dialogLayout}px)`]: {
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    [`@media (max-width:${theme.breakpoints.values.dialogLayout - 1}px)`]: {
        paddingLeft: "12px",
        paddingRight: "12px",
    },
    "&:hover > * > *" :{
        fill: theme.palette.black60.main
    },
}))

const ButtonText = styled('div')(({theme}) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "block",
    textOverflow: "ellipsis",
    paddingLeft: "5px",
    paddingRight: "5px",
    [`@media (min-width:${theme.breakpoints.values.dialogButton}px)`]: {
        display: "block",
    },
    [`@media (max-width:${theme.breakpoints.values.dialogButton - 1}px)`]: {
        display: "none",
    },
}))

const CopyButton = styled(ButtonLGPrimary)(({theme}) => ({
    [`@media (min-width:${theme.breakpoints.values.dialogButton}px)`]: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "6px",
        paddingBottom: "6px",
    },
    [`@media (max-width:${theme.breakpoints.values.dialogButton - 1}px)`]: {
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingTop: "3px",
        paddingBottom: "3px",
    },
    "& > * > span": {
        marginLeft: "0px",
        marginRight: "0px"
    }
}))

const StyledContinueButton = styled(({width, ...rest}) => <ButtonLGSecondary {...rest}/>)(({width}: {width: string}) => ({
    width: `max(${width}, 130px)`
}))

const StyledDialogTextContent = styled(DialogContent)(({theme}) => ({
    paddingBottom: 0,
    [`@media (max-width:${theme.breakpoints.values.dialogLayout - 1}px)`]: {
        paddingLeft: "12px",
        paddingRight: "12px",
    },
}))

const StyledDialogLinkContent = styled(DialogContent)(({theme}) => ({
    paddingBottom: 0,
    paddingRight: 0,
    [`@media (max-width:${theme.breakpoints.values.dialogLayout - 1}px)`]: {
        paddingLeft: "12px",
    },
}))

export const StyledDialogActions = styled(DialogActions)(({theme}) => ({
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: "24px",
    [`@media (max-width:${theme.breakpoints.values.dialogLayout - 1}px)`]: {
        paddingLeft: "12px",
    },
}))

export const LinkTextField = styled('input')(({theme}) => ({
    borderStyle: "solid",
    borderWidth: "1px",
    flexGrow: 1,
    textOverflow: "ellipsis",
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),

    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#666666",

    "@media (min-width: 400px)": {
        fontSize: "18px",
        lineHeight: "26px",
    },
    "@media (min-width: 1280px)": {
        fontSize: "20px",
        lineHeight: "30px",
    },
}))
