import { AppBar, Box, Container, Grid, styled } from "@material-ui/core";
import React from "react";
import { gutterWidth } from "../utils/layout";
import { ActionContainer } from "./LandingPage/Actions";

export const ButtonContainer = styled((props) => <Grid {...props} item container
                                                       justifyContent="flex-end"/>)(({theme}) => ({
    '& > *': {
        margin: theme.spacing(1),
    },
    '@media print': {
        display: "none",
    }
}))

export const ButtonGroupContainer = styled((props) => <Grid {...props} item container
                                                            justifyContent="space-between"/>)(({theme}) => ({
    '& > *': {
        margin: theme.spacing(1),
    },
    '@media print': {
        display: "none",
    }
}))

export const FormContainer = styled('div')(({theme}) => ({
    height: '100%',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    flexGrow: 1,
    [`@media (min-width:${theme.breakpoints.values.layout}px)`]: {
        paddingLeft: gutterWidth,
        paddingRight: gutterWidth,
        marginLeft: -gutterWidth,
        marginRight: -gutterWidth,
    },
    [`@media (max-width:${theme.breakpoints.values.layout - 1}px)`]: {
        paddingLeft: gutterWidth / 2,
        paddingRight: gutterWidth / 2,
        marginLeft: -gutterWidth / 2,
        marginRight: -gutterWidth / 2,
    }
}))

export const StyledActionContainer = styled(ActionContainer)({
    display: 'flex',
    flexDirection: 'column',
})

export const Footer = styled(props => <AppBar component="footer" variant="outlined" {...props}/>)(({theme}) => ({
    border: "none",
    backgroundColor: theme.palette.grey["800"],
    color: theme.palette.common.white,
}))

export const MaxWidthContainer = styled(Container)({
    maxWidth: "100% !important",
    paddingRight: "0px",
    paddingLeft: "0px"
})

export const HeaderIconBox = styled(Box) ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const ImageContainer = styled(Box)({
    display: "flex",
    flexDirection: "column"
})