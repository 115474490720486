import React from "react";
import { fromRFFKey, Resources, resourceSpec, toRFFKey } from "../../api/Resources";
import { useResources } from "../../context/ResourceProvider";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Button } from "@material-ui/core";
import { ButtonContainer } from "../../components/Containers";
import { useResourceApi } from "../../api/AdminHooks";
import _ from "lodash";

export function EditResources() {
    const {resources, refresh} = useResources()
    const {update} = useResourceApi()
    const submit = (values: any) => {
        const updates = fromRFFObject(values);
        const fillers = Object.fromEntries(Object.entries(resources).map(([key, ]) => [key, ""]))
        const diff = Object.fromEntries(Object.entries({...fillers, ...updates}).filter(([key, value]) => resources[key as keyof Resources] !== value))
        if (!_.isEmpty(diff)) {
            update(diff)
                .then(() => refresh())
        }
    }
    return <>
        <Form initialValues={toRFFObject(resources)} onSubmit={submit} render={({pristine, handleSubmit, form}) =>
            <form onSubmit={handleSubmit} onReset={form.reset}>
                {Object.entries(resourceSpec).map(([key, value]) =>
                    <TextField key={key}
                               fullWidth
                               margin="normal"
                               name={toRFFKey(key)}
                               label={value.label}
                               InputLabelProps={{shrink: true}}/>
                )}
                <ButtonContainer>
                    <Button disabled={pristine} variant="outlined" type="reset">Zurücksetzen</Button>
                    <Button disabled={pristine} color="primary" variant="outlined" type="submit">Speichern</Button>
                </ButtonContainer>
            </form>}/>
    </>
}

function toRFFObject(obj: { [name: string]: string }): any {
    return Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [toRFFKey(k), v]))
}

function fromRFFObject(obj: { [name: string]: string }): any {
    return Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [fromRFFKey(k), v]))
}