import { styled, Typography } from "@material-ui/core";

export const BreadCrumbInactive = styled(Typography)(({theme}) => ({
    color: theme.palette.white100.main,
    flexGrow: 1,
    textAlign: "center",
    whiteSpace: "normal",
    fontWeight: 400,

    [`@media (max-width:${theme.breakpoints.values.xs - 1}px)`]: {
        lineHeight: "24px",
        fontSize: "16px",
        "&:hover": {
            color: theme.palette.white70.main,
        },
    },
    [`@media (max-width:${theme.breakpoints.values.xl - 1}px)`]: {
        lineHeight: "26px",
        fontSize: "18px",
        "&:hover": {
            color: theme.palette.white70.main,
        },
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        lineHeight: "30px",
        fontSize: "20px",
        "&:hover": {
            color: theme.palette.white70.main,
        },
    },
}))
export const BreadCrumbActive = styled(Typography)(({theme}) => ({
    color: theme.palette.white70.main,
    flexGrow: 1,
    textAlign: "center",
    whiteSpace: "normal",
    fontWeight: 400,

    [`@media (max-width:${theme.breakpoints.values.xs - 1}px)`]: {
        lineHeight: "24px",
        fontSize: "16px",
    },
    [`@media (max-width:${theme.breakpoints.values.xl - 1}px)`]: {
        lineHeight: "26px",
        fontSize: "18px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        lineHeight: "30px",
        fontSize: "20px",
    },
}))