import React, { useEffect, useMemo, useState } from "react";
import { ProcessInstance } from "./Model";
import axios, { AxiosInstance } from "axios";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { Resources } from "./Resources";

export interface PatchInstance {
    comments: { [key: string]: string },
    selectedFunctions: { [key: string]: boolean },
}

export function useInstanceApi() {
    const client = useRestClient()
    const snackbar = useSnackbar();

    const create = (instance: Partial<ProcessInstance>) => {
        return client.post<string>("/instances", instance)
            .then(response => response.data)
            .catch(error => {
                if (error.response && error.response.status === 429) {
                    const retryAfter = error.response.headers ? error.response.headers["x-rate-limit-retry-after-seconds"] : undefined
                    let message = "Sie haben das Limit an erlaubten Anfragen erreicht. Bitte versuchen Sie es später nochmal"
                    if (Number(retryAfter)) {
                        const retryDate = new Date(new Date().getTime() + Number(retryAfter) * 1000);
                        message = `Sie haben das Limit an erlaubten Anfragen erreicht. Ab ${retryDate.toLocaleTimeString("de-CH", {
                            hour: "2-digit",
                            minute: "2-digit"
                        })} können wieder neue Instanzen erstellt werden`
                    }
                    snackbar.enqueueSnackbar(message, {
                        variant: "error", persist: true, action: (key) => (
                            <Button onClick={() => snackbar.closeSnackbar(key)} color="inherit">OK</Button>
                        )
                    })
                }
                return Promise.reject(error)
            })
    }

    return {create}
}

export function useRemoteResources() {
    const client = useRestClient()
    const [resources, setResources] = useState<Partial<Resources>>()
    const [dummy, setDummy] = useState({})
    const refresh = () => setDummy({})

    useEffect(() => {
        const source = axios.CancelToken.source()
        client.get(`/resources`, {
            cancelToken: source.token
        })
            .then(response => setResources(response.data))
            .catch(noop)
        return () => source.cancel()
    }, [client, dummy])

    return {resources, refresh}
}

export function useProcessInstance(id: string) {
    const client = useRestClient()
    const snackbar = useSnackbar();
    const [instance, setInstance] = useState<ProcessInstance>()
    const [dummy, setDummy] = useState({})
    const refresh = () => setDummy({})

    useEffect(() => {
        const source = axios.CancelToken.source()
        client.get<ProcessInstance>(`/instances/${id}`, {
            cancelToken: source.token
        })
            .then(response => setInstance(response.data))
            .catch(noop)
        return () => source.cancel()
    }, [id, client, dummy])

    const patch = (instance: Partial<PatchInstance>) => {
        return client.patch(`/instances/${id}`, instance)
            .then(response => noop())
            .catch(() => {
                snackbar.enqueueSnackbar("Fehler beim Speichern", {variant: "error"})
                return Promise.reject()
            })
    }

    const upgrade = () => {
        return client.post(`/instances/${id}/upgrade`)
            .then(response => {
                snackbar.enqueueSnackbar("Projekt wurde aktualisiert", {variant: "success"})
                refresh()
            })
            .catch(() => {
                snackbar.enqueueSnackbar("Fehler beim Aktualisieren", {variant: "error"})
            })
    }

    return {instance, patch, upgrade}
}

export function useProcessInstanceViaReadonly(id: string) {
    const client = useRestClient()
    const [instance, setInstance] = useState<ProcessInstance>()

    useEffect(() => {
        const source = axios.CancelToken.source()
        client.get<ProcessInstance>(`/instances/${id}`, {
            cancelToken: source.token,
            params: { readonly: true }
        })
            .then(response => setInstance(response.data))
            .catch(noop)
        return () => source.cancel()
    }, [id, client])

    return {instance}
}

function useRestClient(): AxiosInstance {
    return useMemo(() => {
        return axios.create({baseURL: "/api/v1/public", headers: {"X-Requested-With": "XMLHttpRequest"}})
    }, []);
}

const noop = () => {
}
