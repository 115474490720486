import React from "react";
import { CreateFunction, EditFunction } from "./EditFunction";
import { ListFunctions } from "./ListFunctions";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../../utils/routes";
import { EditResources } from "./EditResources";

export function Admin() {
    return <>
        <Routes>
            <Route path="/functions/create/:step" element={<CreateFunction/>}/>
            <Route path="/functions/edit/:id" element={<EditFunction/>}/>
            <Route path="/functions/:step" element={<ListFunctions/>}/>
            <Route path="/resources" element={<EditResources/>}/>
            <Route path="/" element={<Navigate replace to={routes.admin.functions.list(0)}/>}/>
        </Routes>
    </>
}