import React, { useCallback, useEffect, useState } from "react";
import {
    Fab,
    Icon,
    IconButton, styled,
    Table,
    TableBody,
    TableCell, TableCellProps,
    TableContainer,
    TableHead,
    TableRow,
    withStyles
} from "@material-ui/core";
import { BusinessFunction, ReferenceProcess } from "../../api/Model";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useFunctionApi, useReferenceProcess } from "../../api/AdminHooks";
import { Markdown } from "../../components/Markdown";
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from "react-beautiful-dnd";
import { reorder } from "../../utils/reorder";
import { routes } from "../../utils/routes";
import { ConfirmationDialog } from "../../components/Dialog/ConfirmationDialog";
import { checkboxWidth } from "../../utils/layout";
import { steps } from "../../utils/steps";
import { Tab, Tabs } from "../../components/SelectFunctions/Tabs";
import { determineNewSmallGridTemplateColumns } from "../../components/SelectFunctions/SelectFunctions";

const Create = withStyles(({spacing}) => ({
    root: {
        position: 'absolute',
        right: spacing(2),
        bottom: spacing(2),
        zIndex: 1000,
    }
}))(Fab)

export function ListFunctions() {
    const {step} = useParams<any>()
    const {referenceProcess, refresh} = useReferenceProcess()

    return <>{referenceProcess &&
        <ListFunctionsInternal step={Number(step) || 0} referenceProcess={referenceProcess} refresh={refresh}/>}</>
}

export function ListFunctionsInternal(props: {
    step: number,
    referenceProcess: ReferenceProcess,
    refresh: () => void
}) {
    const navigate = useNavigate()
    const {step, referenceProcess, refresh} = props
    const [activeTab, setActiveTab] = useState(props.step);
    const [smallGridTemplateColumns, setSmallGridTemplateColumns] = useState(determineNewSmallGridTemplateColumns(activeTab))

    const createFunction = (step: number) => {
        navigate(routes.admin.functions.create(step), {
            state: {
                step: step,
                customer: {enabled: true, egovDomain: true, name: "", description: ""},
                administration: {enabled: true, egovDomain: true, name: "", description: ""}
            }
        })
    }

    return <>
        <StyledTabs
            value={step}
            onChange={(newValue) => {
                navigate(routes.admin.functions.list(newValue), {replace: true});
                setActiveTab(newValue);
                setSmallGridTemplateColumns(determineNewSmallGridTemplateColumns(newValue));
            }}
            activeTab={activeTab} smallGridTemplateColumns={smallGridTemplateColumns}>
            <Tab label={steps[0]}/>
            <Tab label={steps[1]}/>
            <Tab label={steps[2]}/>
            <Tab label={steps[3]}/>
            <Tab label={steps[4]}/>
        </StyledTabs>

        <FunctionsView functions={referenceProcess.functions.filter(fun => fun.step === step)} refresh={refresh}
                       step={step}/>
        <Create data-cy="add" color="primary" onClick={() => createFunction(step)}><Icon>add</Icon></Create>
    </>;
}

interface FunctionsViewProps {
    step: number,
    functions: BusinessFunction[],
    refresh: () => void
}

function FunctionsView(props: FunctionsViewProps) {
    const [functions, setFunctions] = useState(props.functions)
    useEffect(() => {
        setFunctions(props.functions)
    }, [props.functions])
    const {updateOrder} = useFunctionApi();
    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) {
            return
        }
        setFunctions(functions => {
            const reordered = reorder(functions, result.source.index, result.destination!.index);
            updateOrder(props.step, reordered.map(fun => fun.id))
                .then(() => props.refresh())
                .catch(console.log)
            return reordered
        })
    }, [props.step, updateOrder])

    return <DragDropContext onDragEnd={onDragEnd}>
        <TableContainer>
            <Table style={{tableLayout: "fixed", overflow: "hidden"}}>
                <colgroup>
                    <col width="50%"/>
                    <col width={checkboxWidth}/>
                    <col width="50%"/>
                    <col width={checkboxWidth}/>
                    <col width={checkboxWidth}/>
                    <col width={checkboxWidth}/>
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Kundin/Kunde
                        </TableCell>
                        <TableCell/>
                        <TableCell>
                            Verwaltung
                        </TableCell>
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <Droppable droppableId="tableBody">
                    {(provided) =>
                        <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                            {functions.map((fun, i) => (
                                <Draggable draggableId={fun.id} index={i} key={fun.id}>
                                    {(provided, snapshot) => (
                                        <Row provided={provided} isDragging={snapshot.isDragging} key={fun.id} fun={fun}
                                             reload={props.refresh}/>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </TableBody>
                    }
                </Droppable>
            </Table>
        </TableContainer>
    </DragDropContext>
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props: { fun: BusinessFunction, reload: () => any, provided: DraggableProvided, isDragging: boolean }) {
    const navigate = useNavigate()
    const [details, setDetails] = useState(false)
    const classes = useRowStyles()

    const customerDescription = props.fun.customer.enabled ? props.fun.customer.description : undefined
    const administrationDescription = props.fun.administration.enabled ? props.fun.administration.description : undefined

    const editFunction = (fun: BusinessFunction) => {
        navigate(routes.admin.functions.edit(fun.id))
    }

    const {remove} = useFunctionApi()

    function closeDialog(confirmed: boolean) {
        setDialogOpen(false);
        if (confirmed) {
            remove(props.fun).then(() => props.reload())
        }
    }

    const [dialogOpen, setDialogOpen] = useState(false)
    const functionName = `${props.fun.customer.enabled ? props.fun.customer.name : ''}${props.fun.administration.enabled && props.fun.customer.enabled ? ' / ' : ''}${props.fun.administration.enabled ? props.fun.administration.name : ''}`

    return <>
        <TableRow ref={props.provided.innerRef} className={classes.root}
                  {...props.provided.draggableProps}
                  {...props.provided.dragHandleProps}
        >
            <ConfirmationDialog message={`Wollen Sie die Funktion ${functionName} wirklich löschen?`} open={dialogOpen}
                                onClose={closeDialog}/>
            <DraggableTableCell
                isDragging={props.isDragging}>{props.fun.customer.enabled ? props.fun.customer.name : ""}</DraggableTableCell>
            <DraggableTableCell isDragging={props.isDragging} padding="checkbox">
                {props.fun.customer.enabled &&
                    <IconButton onClick={() => setDetails(!details)}
                                disabled={!customerDescription && !administrationDescription}>
                        <Icon>{details ? "close" : "info"}</Icon>
                    </IconButton>}
            </DraggableTableCell>
            <DraggableTableCell
                isDragging={props.isDragging}>{props.fun.administration.enabled ? props.fun.administration.name : ""}</DraggableTableCell>
            <DraggableTableCell isDragging={props.isDragging} padding="checkbox">
                {props.fun.administration.enabled &&
                    <IconButton onClick={() => setDetails(!details)}
                                disabled={!customerDescription && !administrationDescription}>
                        <Icon>{details ? "close" : "info"}</Icon>
                    </IconButton>}
            </DraggableTableCell>
            <DraggableTableCell isDragging={props.isDragging} padding="checkbox">
                <IconButton onClick={() => editFunction(props.fun)}>
                    <Icon>edit</Icon>
                </IconButton>
            </DraggableTableCell>
            <DraggableTableCell isDragging={props.isDragging} padding="checkbox">
                <IconButton onClick={() => setDialogOpen(true)}>
                    <Icon>delete</Icon>
                </IconButton>
            </DraggableTableCell>
        </TableRow>
        <TableRow style={details ? {} : {visibility: "collapse"}}>
            <TableCell>
                {props.fun.customer.enabled &&
                    <Markdown>
                        {customerDescription}
                    </Markdown>}
            </TableCell>
            <TableCell/>
            <TableCell>
                {props.fun.administration.enabled &&
                    <Markdown>
                        {administrationDescription}
                    </Markdown>}
            </TableCell>
            <TableCell/>
            <TableCell/>
            <TableCell/>
        </TableRow>
    </>
}

interface DraggableTableCellProps extends TableCellProps {
    isDragging: boolean
}

interface DraggableTableCellSnapshot {
    width: number,
    height: number,
}

class DraggableTableCell extends React.Component<DraggableTableCellProps> {
    private ref?: HTMLTableCellElement

    getSnapshotBeforeUpdate(prevProps: DraggableTableCellProps): DraggableTableCellSnapshot | null {
        if (!this.ref) {
            return null
        }

        const isDragStarting = this.props.isDragging && !prevProps.isDragging;

        if (!isDragStarting) {
            return null
        }

        return this.ref.getBoundingClientRect()
    }

    componentDidUpdate(prevProps: DraggableTableCellProps,
                       prevState: DraggableTableCellProps,
                       snapshot?: DraggableTableCellSnapshot,
    ) {
        if (!this.ref) {
            return
        }

        if (snapshot) {
            this.ref.style.width = `${snapshot.width}px`
            this.ref.style.height = `${snapshot.height}px`
            return
        }

        if (this.props.isDragging) {
            return
        }

        // inline styles not applied
        if (this.ref.style.width == null) {
            return
        }

        // no snapshot and drag is finished - clear the inline styles
        this.ref.style.removeProperty('height')
        this.ref.style.removeProperty('width')
    }

    render() {
        const setRef = (ref?: HTMLTableCellElement) => this.ref = ref
        const {isDragging, children, ...props} = this.props
        return <TableCell ref={setRef} {...props}>{children}</TableCell>;
    }
}

const StyledTabs = styled(Tabs)(({theme}) => ({
    marginTop: theme.spacing(4),
    gridTemplateColumns: 'repeat(5, 20%)'
}))
