import React from "react";
import { styled, Typography } from "@material-ui/core";
import { ReactComponent as SvgLogo } from "../../images/ergon.svg";

import { LinkPrimary } from "../../components/Styleguide/Link";

export function Impressum() {
    return <>
        <Typography variant={"h1"} style={{ marginTop: "24px" }}>Impressum</Typography>
        <Typography variant={"h2"}>Inhaltliche Verantwortung</Typography>
        <Typography variant={"body1"}>
            Staatskanzlei, Digitale Verwaltung<br/>
            Neumühlequai 10<br/>
            Postfach<br/>
            8090 Zürich<br/>
        </Typography>
        <Typography variant={"body1"}>

            E-Mail: <LinkPrimary href="mailto:digitaleverwaltung@sk.zh.ch">digitaleverwaltung@sk.zh.ch</LinkPrimary>
        </Typography>
        <Typography variant={"h2"}>Technische Verantwortung</Typography>
        <Logo/>
        <Typography variant={"body1"}>
            Ergon Informatik AG<br/>
            Merkurstrasse 43<br/>
            8032 Zürich<br/>
            Schweiz<br/>
        </Typography>
        <Typography variant={"body1"}>
            E-Mail: <LinkPrimary href="mailto:info@ergon.ch">info@ergon.ch</LinkPrimary>
        </Typography>
    </>
}

const Logo = styled(SvgLogo)({
    marginTop: "15px",
    marginBottom: 0,
    width: "70px",
    height: "auto",
})
