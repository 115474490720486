import { Grid } from "@material-ui/core";
import React from "react";
import { Checkboxes, TextField } from "mui-rff";
import { FormSpy } from "react-final-form";

interface Props {
    name: string
}

export function FunctionData({ name }: Props) {
    return <>
        <Grid container>
            <Checkboxes
                color="primary"
                name={`${name}.enabled`}
                data={{ label: 'Aktiv', value: undefined }}
            />
        </Grid>
        <FormSpy subscription={{ values: true }}>
            {props => {
                const values = props.values[name];
                const disabled = !values?.enabled ?? false;
                return <>
                    <Grid container>
                        <TextField
                            required
                            color="primary"
                            disabled={disabled}
                            name={`${name}.name`}
                            fullWidth
                            label="Name"/>
                    </Grid>
                    <Grid container>
                        <TextField
                            disabled={disabled}
                            color="primary"
                            name={`${name}.description`}
                            fullWidth
                            multiline
                            rows={6}
                            label="Beschreibung"/>
                    </Grid>
                    <Grid container>
                        <TextField
                            disabled={disabled}
                            color="primary"
                            name={`${name}.recommendation`}
                            fullWidth
                            multiline
                            rows={6}
                            label="Empfehlung"/>
                    </Grid>
                </>;
            }}
        </FormSpy>
    </>;
}
