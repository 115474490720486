import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";

declare module "@material-ui/core/styles/createBreakpoints" {
    interface BreakpointOverrides {
        xxs: true;
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;

        layout: true;
        summaryLayout: true;
        landingTable: true;
        dialogLayout: true;
        dialogButton: true;
        selectionLayout: true;
        selectionButton: true;
        breadCrumb: true;
        breadCrumbEllipsis: true;
        selectFunctionsTab: true;
        legend: true;
    }
}

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        customer: Palette['primary'];
        administration: Palette['primary'];
        contactGrey: Palette['primary'];
        borderGrey: Palette['primary'];

        black100: Palette['primary'];
        black80: Palette['primary'];
        black60: Palette['primary'];
        black20: Palette['primary'];
        black10: Palette['primary'];
        black5: Palette['primary'];
        zueriBlue: Palette['primary'];
        white100: Palette['primary'];
        white70: Palette['primary'];
    }

    interface PaletteOptions {
        customer: PaletteOptions['primary'];
        administration: PaletteOptions['primary'];
        contactGrey: PaletteOptions['primary'];
        borderGrey: PaletteOptions['primary'];

        black100: PaletteOptions['primary'];
        black80: PaletteOptions['primary'];
        black60: PaletteOptions['primary'];
        black20: PaletteOptions['primary'];
        black10: PaletteOptions['primary'];
        black5: PaletteOptions['primary'];
        zueriBlue: PaletteOptions['primary'];
        white100: PaletteOptions['primary'];
        white70: PaletteOptions['primary'];
    }
}

const publicTheme = createTheme({
    typography: {
        fontFamily: "HelveticaNowText-Regular, sans-serif",
        h1: {
            fontFamily: "HelveticaNowText-Black, sans-serif",
            fontSize: "24px",
            lineHeight: "30px",
            marginBottom: "12px",
            "@media (min-width: 400px)": {
                fontSize: "24px",
                lineHeight: "30px",
                marginBottom: "16px",
            },
            "@media (min-width: 600px)": {
                fontSize: "28px",
                lineHeight: "34px",
            },
            "@media (min-width: 840px)": {
                fontSize: "32px",
                lineHeight: "38px",
                marginBottom: "20px",
            },
            "@media (min-width: 1024px)": {
                fontSize: "40px",
                lineHeight: "48px",
            },
            "@media (min-width: 1280px)": {
                fontSize: "48px",
                lineHeight: "48px",
                marginBottom: "24px",
            },
        },
        h2: {
            fontFamily: "HelveticaNowText-Black, sans-serif",
            fontSize: "18px",
            lineHeight: "24px",
            marginBottom: "12px",
            "@media (min-width: 400px)": {
                fontSize: "20px",
                lineHeight: "26px",
                marginBottom: "16px",
            },
            "@media (min-width: 600px)": {
                fontSize: "20px",
                lineHeight: "26px",
            },
            "@media (min-width: 840px)": {
                fontSize: "20px",
                lineHeight: "26px",
                marginBottom: "20px",
            },
            "@media (min-width: 1024px)": {
                fontSize: "24px",
                lineHeight: "30px",
            },
            "@media (min-width: 1280px)": {
                fontSize: "28px",
                lineHeight: "34px",
                marginBottom: "24px",
            },

        },
        h3: {
            fontFamily: "HelveticaNowText-Black, sans-serif"
        },
        h4: {
            fontFamily: "HelveticaNowText-Black, sans-serif"
        },
        h5: {
            fontFamily: "HelveticaNowText-Black, sans-serif"
        },
        h6: {
            fontWeight: 700,
        },
        button: {
            fontFamily: "HelveticaNowText-Regular, sans-serif",
            fontWeight: 700,
            textTransform: "none",
        },
        body1: {
            fontFamily: "HelveticaNowText-Regular, sans-serif",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#666666",
            marginBottom: "12px",

            "@media (min-width: 400px)": {
                fontSize: "18px",
                lineHeight: "26px",
                marginBottom: "16px",
            },
            "@media (min-width: 840px)": {
                marginBottom: "20px",
            },
            "@media (min-width: 1280px)": {
                fontSize: "20px",
                lineHeight: "30px",
                marginBottom: "24px",
            },

            overflow:  undefined,
            textOverflow: undefined,
            whiteSpace: "normal",
            wordBreak: "break-word",
        },
        body2: {
            fontWeight: 900,
            fontSize: "18px",
            lineHeight: "24px",
            marginBottom: "12px",

            "@media (min-width: 400px)": {
                marginBottom: "16px",
            },
            "@media (min-width: 840px)": {
                marginBottom: "20px",
            },
            "@media (min-width: 600px)": {
                fontSize: "20px",
                lineHeight: "26px",
            },
            "@media (min-width: 1280px)": {
                fontSize: "24px",
                lineHeight: "30px",
                marginBottom: "24px",
            },

            overflow:  undefined,
            textOverflow: undefined,
            whiteSpace: "normal",
            wordBreak: "break-word",
        },
        caption: {
            fontFamily: "HelveticaNowText-Regular, sans-serif",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: "#00000099",
            marginBottom: "12px",

            "@media (min-width: 400px)": {
                fontSize: "14px",
                lineHeight: "20px",
            },
            "@media (min-width: 1280px)": {
                marginBottom: "16px",
            },

        }
    },
    shape: {
        borderRadius: 0
    },
    palette: {
        primary: {
            light: "#0070B4",
            main: "#00407c",
            contrastText: "#fff"
        },
        secondary: {
            main: "#000000",
        },

        customer: {
            main: "#E8F3F2"
        },
        administration: {
            main: "#E0E8EE"
        },
        contactGrey: {
            main: "#CCCCCC"
        },
        borderGrey: {
            main: "#e0e0e0"
        },

        black100: {
          main: "#000000"
        },
        black80: {
            main: "#000000CC"
        },
        black60: {
            main: "#00000099"
        },
        black20: {
            main: "#00000033"
        },
        black10: {
            main: "#0000001A"
        },
        black5: {
            main: "#0000000D"
        },
        white100: {
          main: "#FFFFFE"
        },
        white70: {
            main: "#FFFFFFB2"
        },
        zueriBlue: {
            main: "#009EE0"
        },
    },
    breakpoints: {
        values: {
            xxs: 0,
            xs: 400,
            sm: 600,
            md: 840,
            lg: 1024,
            xl: 1280,

            layout: 450,
            summaryLayout: 720,
            landingTable: 800,
            dialogLayout: 450,
            dialogButton: 410,
            selectionLayout: 800,
            selectionButton: 600,
            breadCrumb: 445,
            breadCrumbEllipsis: 570,
            selectFunctionsTab: 1070,
            legend: 485,
        },
    },
    shadows: Array(25).fill("none") as any,
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: "#FFFFFF"
                }
            }
        }
    }
})

const adminTheme = createTheme({
    typography: {
        fontFamily: "HelveticaNowText-Regular, sans-serif"
    },
    shape: {
        borderRadius: 0
    },
    palette: {
        primary: {
            main: "#7f3da7",
            contrastText: "#fff",
        },
        secondary: {
            main: "#bdbdbd",
        },

        customer: {
            main: "#E8F3F2"
        },
        administration: {
            main: "#E0E8EE"
        },
        contactGrey: {
            main: "#CCCCCC"
        },
        borderGrey: {
            main: "#e0e0e0"
        },

        black100: {
            main: "#000000"
        },
        black80: {
            main: "#000000CC"
        },
        black60: {
            main: "#00000099"
        },
        black20: {
            main: "#00000033"
        },
        black10: {
            main: "#0000001A"
        },
        black5: {
            main: "#0000000D"
        },
        white100: {
            main: "#FFFFFE"
        },
        white70: {
            main: "#FFFFFFB2"
        },
        zueriBlue: {
            main: "#009EE0"
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: "#eae4ed"
                }
            }
        }
    }
})

export function PublicThemeProvider(props: React.PropsWithChildren<{}>) {
    return <ThemeProvider theme={publicTheme}>
        <CssBaseline/>
        {props.children}
    </ThemeProvider>
}

export function AdminThemeProvider(props: React.PropsWithChildren<{}>) {
    return <ThemeProvider theme={adminTheme}>
        <CssBaseline/>
        {props.children}
    </ThemeProvider>
}