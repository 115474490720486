import React from "react";
import { styled, Typography } from "@material-ui/core";
import { gutterWidth } from "../../utils/layout";
import { LinkPrimary } from "../Styleguide/Link";

export function EGovNavigatorHeader() {
    return (
        <Title>
            <Typography variant="h2">
                Digitalisierungsprojekte in Verwaltungen einfach planen
            </Typography>
            <Typography variant="body1" style={{ marginBottom: 0 }}>
                mit dem Online-Tool zum <LinkPrimary href="https://www.ech.ch/de/ech/ech-0123/2.0.0" target="_blank" rel="noopener">eCH-0123 Standard.</LinkPrimary>
            </Typography>
        </Title>
    )
}

const Title = styled('div')(({theme}) => ({
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.grey["100"],
    [`@media (min-width:${theme.breakpoints.values.layout}px)`]: {
        marginLeft: -gutterWidth,
        marginRight: -gutterWidth,
        paddingLeft: gutterWidth,
        paddingRight: gutterWidth,
    },
    [`@media (max-width:${theme.breakpoints.values.layout - 1}px)`]: {
        marginLeft: -gutterWidth / 2,
        marginRight: -gutterWidth / 2,
        paddingLeft: gutterWidth / 2,
        paddingRight: gutterWidth / 2,
    }
}))
