import React from "react";
import { gutterWidth } from "../../utils/layout";
import { routes } from "../../utils/routes";
import { Footer, MaxWidthContainer } from "../Containers";
import { useSnapshotInfo } from "../../context/SnapshotInfoProvider";
import { formatDateString, formatTimeString } from "../../utils/formatting";
import {
    AppBar,
    Box,
    Container,
    Icon,
    Toolbar as MuiToolbar,
    List,
    ListItem,
    styled
} from "@material-ui/core";
import { LinkContact, LinkNegative, LinkRouter } from "../Styleguide/Link";

export function AdminFooter() {
    const {latestSnapshotDate, outdated} = useSnapshotInfo();
    return (
        <StyledContainer disableGutters>
            <FixedFooter position="fixed">
                <Toolbar>{latestSnapshotDate && <>
                    Letzte Version veröffentlicht
                    am {formatDateString(latestSnapshotDate)} um {formatTimeString(latestSnapshotDate)}
                    {outdated && " – es gibt unveröffentlichte Änderungen"}
                </>}</Toolbar>
            </FixedFooter>
        </StyledContainer>
    )
}

const FixedFooter = styled(Footer)(({theme}) => ({
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 0,
    maxWidth: theme.breakpoints.values.xl,
    zIndex: 1,
}))

export function PublicFooter() {
    const currentYear = new Date().getFullYear();

    return (
    <MaxWidthContainer>
        <ContactMaxWidthContainer>
            <StyledContainer disableGutters>
                <AppBar style={{border: 'none'}} component="footer" position="static" variant="outlined">
                    <ContactBar disableGutters>
                        <ContactTitle>Kontakt</ContactTitle>
                        <ContactDepartmentLinkContainer>
                            <ContactDepartmentLink href="https://www.zh.ch/de/staatskanzlei/digitale-verwaltung.html" underline="always" target="_blank" rel="noopener">Staatskanzlei, Digitale Verwaltung</ContactDepartmentLink>
                        </ContactDepartmentLinkContainer>
                        <ContactList>
                            <ContactListItem>
                                <RoundIcon>place</RoundIcon>
                                <ContactText>
                                    Postfach<br/>
                                    Neumühlequai 10<br/>
                                    8090 Zürich<br/>
                                </ContactText>
                            </ContactListItem>
                            <ContactListItem>
                                <RoundIcon>email</RoundIcon>
                                <ContactLink href="mailto:digitaleverwaltung@sk.zh.ch" underline="always">
                                    <BreakText>
                                        digitaleverwaltung@sk.zh.ch
                                    </BreakText>
                                </ContactLink>
                            </ContactListItem>
                        </ContactList>
                    </ContactBar>
                </AppBar>
            </StyledContainer>
        </ContactMaxWidthContainer>
        <FooterMaxWidthContainer>
            <StyledContainer disableGutters>
                <Footer position="static">
                    <FooterBar disableGutters>
                        <FooterH2>Kanton Zürich</FooterH2>
                        <FooterList>
                            <FooterListItem >
                                <FooterText>©{currentYear} Kanton Zürich</FooterText>
                            </FooterListItem >
                            <FooterListItem >
                                <Box style={{ display: "flex" }}>
                                    <FooterLinkInternal to={routes.public.impressum()} data-cy="impressum">Impressum</FooterLinkInternal>
                                </Box>
                            </FooterListItem >
                            <FooterListItem >
                                <Box style={{ display: "flex" }}>
                                    <FooterLinkExternal href="https://www.zh.ch/de/nutzungshinweise.html" underline="none" data-cy="nutzungshinweis" target="_blank" rel="noopener">Nutzungshinweis</FooterLinkExternal>
                                </Box>
                            </FooterListItem >
                        </FooterList>
                    </FooterBar>
                </Footer>
            </StyledContainer>
        </FooterMaxWidthContainer>
    </MaxWidthContainer>
    )
}

const ContactTitle = styled('h2')(({theme}) => ({
    fontWeight: 900,
    marginBottom: 0,
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        marginTop: "40px",
        fontSize: 20,
        lineHeight: "26px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        marginTop: "32px",
        fontSize: 18,
        lineHeight: "24px"
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        marginTop: "56px",
        fontSize: 28,
        lineHeight: "34px",
    },
}))

const ContactDepartmentLinkContainer = styled('div')(({theme}) => ({
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        marginTop: "32px !important",
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        marginTop: "24px !important"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        marginTop: "20px !important",
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        marginTop: "40px !important",
    }
}))

const ContactDepartmentLink = styled(LinkNegative)(({theme}) => ({
    whiteSpace: "pre-wrap",
    fontWeight: 900,
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        marginTop: "32px",
        fontSize: 20,
        lineHeight: "26px"
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        marginTop: "24px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        marginTop: "20px",
        fontSize: 18,
        lineHeight: "24px"
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        marginTop: "40px",
        fontSize: 28,
        lineHeight: "34px"
    }
}))

const ContactText = styled('p')(({theme}) => ({
    fontWeight: 900,
    marginTop: 0,
    marginBottom: 0,
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        fontSize: 18,
        lineHeight: "24px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        fontSize: 16,
        lineHeight: "24px"
    }
}))

const ContactLink = styled(LinkNegative)(({theme}) => ({
    whiteSpace: "pre-wrap",
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
        fontSize: 18,
        lineHeight: "24px"
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        fontSize: 16,
        lineHeight: "24px"
    }
}))

const BreakText = styled('span')({
    wordBreak: "break-word",
})

const ContactList = styled(List)(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "32px",
    flexDirection: "row",
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        marginBottom: "40px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        marginBottom: "32px"
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        marginBottom: "56px"
    }
}))

const ContactListItem = styled(({...rest}) => <ListItem button={false} {...rest}/>)(({})=>({
    display: "flex",
    alignItems: "normal",
    whiteSpace: "pre",
    padding: 0,
    marginBottom: "24px",
    marginRight: "64px",
    maxWidth: "fit-content",
    "&:last-child": {
        marginBottom: 0
    }
}))


const RoundIcon = styled(Icon)(({theme}) => ({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: '50%',
    lineHeight: 1,
    fontSize: "20px",
    height: '28px',
    width: '28px',
    display: 'flex',
    flexShrink: 0,
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: "16px",
    marginRight: "16px"
}))

const Toolbar = styled(MuiToolbar)({
    paddingLeft: gutterWidth,
    paddingRight: gutterWidth,
})

const ContactBar = styled(Toolbar)(({theme}) => ({
    display: 'block',
    minHeight: "240px",
    [`@media (max-width:${theme.breakpoints.values.layout - 1}px)`]: {
        paddingLeft: gutterWidth / 2,
        paddingRight: gutterWidth / 2,
    }
}))

const FooterBar = styled(Toolbar)(({theme}) => ({
    display: 'block',
    [`@media (max-width:${theme.breakpoints.values.layout - 1}px)`]: {
        paddingLeft: gutterWidth / 2,
        paddingRight: gutterWidth / 2,
    }
}))

const StyledContainer = styled(({...rest}) => <Container maxWidth={'xl'} {...rest}/>)(() => ({
    '@media print': {
        display: "none",
    }
}))

const ContactMaxWidthContainer = styled(MaxWidthContainer)(({theme}) => ({
    backgroundColor: theme.palette.primary.main
}))

const FooterMaxWidthContainer = styled(MaxWidthContainer)(({theme}) => ({
    backgroundColor: theme.palette.grey["800"]
}))

const FooterH2= styled('h2')(({theme}) => ({
    marginBottom: 0,
    fontWeight: 900,
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        fontSize: 40,
        lineHeight: "48px"
    },
    [`@media (max-width: ${theme.breakpoints.values.lg - 1}px)`]: {
        marginTop: "40px",
        fontSize: 32,
        lineHeight: "38px"
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        fontSize: 28,
        lineHeight: "34px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        marginTop: "32px",
        fontSize: 24,
        lineHeight: "30px"
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        marginTop: "56px"
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        fontSize: 48,
        lineHeight: "48px"
    },
}))

const FooterList = styled(List)(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 0,
    paddingBottom: 0,
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        marginTop: "20px",
        marginBottom: "56px"
    },
    [`@media (max-width: ${theme.breakpoints.values.lg - 1}px)`]: {
        marginBottom: "40px"
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        marginTop: "16px",
        marginBottom: "40px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        marginTop: "12px",
        marginBottom: "32px"
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        marginTop: "24px",
        marginBottom: "56px"
    }
}))

const FooterListItem = styled(List)(({theme}) => ({
    display: "list-item",
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: "4px",
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        marginRight: "12px",
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        marginRight: "8px",
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        marginRight: "16px",
    }
}))

const FooterText = styled('p')(({theme}) => ({
    color: theme.palette.contactGrey.main,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    fontWeight: 900,
    [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
        fontSize: 14,
        lineHeight: "20px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        fontSize: 12,
        lineHeight: "18px"
    }
}))

const FooterLinkInternal = styled(LinkRouter)(({theme}) => ({
    fontWeight: 400,
    [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
        fontSize: 14,
        lineHeight: "20px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        fontSize: 12,
        lineHeight: "18px"
    },
}))

const FooterLinkExternal = styled(({...rest}) => <LinkContact {...rest}/>)(({theme}) => ({
    whiteSpace: "pre-wrap",
    fontWeight: 400,
    [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
        fontSize: 14,
        lineHeight: "20px"
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        fontSize: 12,
        lineHeight: "18px"
    }
}))