import { Legend } from "../Summary/FunctionHeader";
import { BusinessFunction, Snapshot } from "../../api/Model";
import React, { useState } from "react";
import { PatchInstance } from "../../api/PublicHooks";
import {
    Box,
    Checkbox,
    Icon,
    IconButton,
    styled,
    Table,
    TableBody,
    TableContainer,
    TableRow, Typography,
} from "@material-ui/core";

interface Props {
    snapshot: Snapshot,
    selectedFunctions: string[],
    patch: (val: Partial<PatchInstance>) => void,
    currentStep: number;
}
export function MobileTable({snapshot, selectedFunctions, patch, currentStep}: Props) {

    snapshot.process.functions.sort((a, b) => a.step < b.step ? -1 : a.step > b.step ? 1 : 0)

    return (
        <>
            <TableContainer>
                <Legend inSelection={true}/>
                <Table style={{tableLayout: "fixed", overflow: "hidden", borderCollapse: "separate"}}>
                    <colgroup>
                        <col width="100%"/>
                    </colgroup>
                    <TableBody>
                        {snapshot.process.functions.map(fun=> (
                            <Row visible={fun.step === currentStep} key={fun.id}
                                 checked={selectedFunctions.includes(fun.id)}
                                 toggle={() => patch({selectedFunctions: {[fun.id]: !selectedFunctions.includes(fun.id)}})}
                                 fun={fun}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>)
}

function Row(props: { fun: BusinessFunction, visible: boolean, checked: boolean, toggle: () => void }) {
    const [details, setDetails] = useState(false)

    const customerDescription = props.fun.customer.enabled ? props.fun.customer.description : undefined
    const administrationDescription = props.fun.administration.enabled ? props.fun.administration.description : undefined
    const hasCustomer = props.fun.customer.enabled
    const hasAdministration = props.fun.administration.enabled
    const rowNumber = (hasCustomer ? 1 : 0) + (hasAdministration ? 1 : 0)

    if (!props.visible) {
        return null;
    }
    return (
        <TableRow>
            <GridRow rowNumber={rowNumber}>
                <CheckboxBox rowNumber={rowNumber} checked={props.checked} onClick={props.toggle}>
                    <StyledCheckbox key={String(props.checked)} data-cy={`checkbox-${props.fun.id}`} color="primary" checked={props.checked}/>
                </CheckboxBox>
                {props.fun.customer.enabled &&
                    <CustomerBox>
                        <HeaderRow>
                            <HeaderBox>
                                <Typography variant={"body1"} style={{ marginBottom: 0 }}>
                                    {props.fun.customer.name}
                                </Typography>
                            </HeaderBox>
                            <IconButton onClick={e => {
                                e.stopPropagation()
                                setDetails(old => !old)
                            }}
                                        disabled={!customerDescription && !administrationDescription}>
                                <Icon>{details ? "close" : "info"}</Icon>
                            </IconButton>
                        </HeaderRow>
                        <MarkdownBox showDetails={details} >
                            <Typography variant={"body1"} style={{ marginBottom: 0 }}>
                                {customerDescription}
                            </Typography>
                        </MarkdownBox>
                    </CustomerBox>
                }
                {props.fun.administration.enabled &&
                    <AdministrationBox>
                        <HeaderRow>
                            <HeaderBox>
                                <Typography variant={"body1"} style={{ marginBottom: 0 }}>
                                    {props.fun.administration.name}
                                </Typography>
                            </HeaderBox>
                            <IconButton onClick={e => {
                                e.stopPropagation()
                                setDetails(old => !old)
                            }}
                                        disabled={!customerDescription && !administrationDescription}>
                                <Icon>{details ? "close" : "info"}</Icon>
                            </IconButton>
                        </HeaderRow>
                        <MarkdownBox showDetails={details} >
                            <Typography variant={"body1"} style={{ marginBottom: 0 }}>
                                {administrationDescription}
                            </Typography>
                        </MarkdownBox>
                    </AdministrationBox>
                }
            </GridRow>
        </TableRow>
    )
}

const StyledCheckbox = styled(Checkbox)({
    marginTop: "auto",
    marginBottom: "auto",
})

const CheckboxBox = styled(({rowNumber, ...rest}) => <Box {...rest}/>)(({checked, rowNumber}: { checked: Boolean, rowNumber: number }) => ({
    display: "flex",
    justifyContent: "center",
    backgroundColor: checked ? "rgba(0,0,0,0.04)" : undefined,
    gridRow: `1/${rowNumber + 1}`,
    '&:hover': {
        cursor: 'pointer',
    }
}))

const CustomerBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    gridColumn: "2",
    backgroundColor: theme.palette.customer.main
}))

const AdministrationBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    gridColumn: "2",
    backgroundColor: theme.palette.administration.main
}))

const HeaderRow = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
})

const GridRow = styled(({rowNumber, ...rest}) => <div {...rest}/>)(({rowNumber}: { rowNumber: number }) => ({
    display: 'grid',
    gridTemplateColumns: "12.5% 87.5%",
    gridTemplateRows: `repeat(${rowNumber}, auto)`,
}))

const HeaderBox  = styled(Box)({
    paddingLeft: "12px",
    paddingRight: "12px",
})

const MarkdownBox  = styled(({showDetails, ...rest}) => <Box {...rest}/>)(({showDetails}: { showDetails: boolean }) => ({
    display: showDetails ? undefined : "none",
    paddingLeft: "12px",
    paddingBottom: "12px",
    paddingTop: "12px",
    paddingRight: "60px",
}))
