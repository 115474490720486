import React, { useState } from "react";
import { useAdminApi } from "../../api/AdminHooks"
import { routes } from "../../utils/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { MaxWidthContainer } from "../Containers";
import { ReactComponent as ArrowRight } from "../../images/icons/angle_right.svg";
import { ReactComponent as ArrowLeft } from "../../images/icons/left_arrow.svg";
import { ReactComponent as Logo } from "../../images/leu.svg";
import { LinkContact } from "../Styleguide/Link";
import { BreadCrumbActive, BreadCrumbInactive } from "../Styleguide/Breadcrumb";
import { MainLogo } from "../Styleguide/Logo";
import { TitleBackText, TitleH1 } from "../Styleguide/Typography";
import {
    AppBar as MuiAppBar,
    Box,
    Container,
    Icon,
    IconButton,
    Link,
    Menu,
    MenuItem,
    styled,
    Tab,
    Tabs,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";

export function PublicAppBar() {
    const theme = useTheme();
    const showBreadcrumbs = useMediaQuery(`(min-width: ${theme.breakpoints.values.breadCrumb}px)`);

    return (
        <HeaderMaxWidthContainer>
            <HeaderContainer disableGutters={true}>
                <HeaderIconLink style={{display: "flex", padding: "8px", marginLeft: "-8px"}} href="https://www.zh.ch/"><MainLogo/></HeaderIconLink>
                <StyledPublicContainer>
                    {showBreadcrumbs ? <BreadCrumbs/> : <BackButton/>}
                    <TitleBox>
                        <TitleH1>Referenz&shy;prozess&shy;ZH</TitleH1>
                    </TitleBox>
                </StyledPublicContainer>
            </HeaderContainer>
        </HeaderMaxWidthContainer>
    )
}

function BackButton() {
    const navigate = useNavigate()
    const location = useLocation();
    const onHomePage = location.pathname === "/"

    return (
        onHomePage ?
            <BackHeaderLinkExternal variant="h6" href="https://www.zh.ch/" underline="none">
                <StyledArrowLeft/>
                <TitleBackText>Kanton Zürich</TitleBackText>
            </BackHeaderLinkExternal>
            :
            <BackHeaderLinkInternal onClick={() => navigate("/")}>
                <StyledArrowLeft/>
                <TitleBackText>Referenz&shy;prozess&shy;ZH</TitleBackText>
            </BackHeaderLinkInternal>
    )
}

function BreadCrumbs() {
    const navigate = useNavigate()
    const location = useLocation();
    const onHomePage = location.pathname === "/"
    const onImpressum = location.pathname === "/impressum"
    const theme = useTheme();
    const showEllipsis = useMediaQuery(`(max-width: ${theme.breakpoints.values.breadCrumbEllipsis}px)`);

    return (
        <AppBar position="static" variant="outlined">
            <BreadCrumbToolbar>
                <HeaderTextBox>
                    <HeaderLinkExternal variant="h6" href="https://www.zh.ch/" underline="none">
                        <BreadCrumbInactive style={{fontWeight: 800}}>
                            Kanton Zürich
                        </BreadCrumbInactive>
                    </HeaderLinkExternal>
                    {onHomePage ?
                        <>
                            <StyledIcon isActive={true}/>
                            <BreadCrumbActive>
                                Referenz&shy;prozess&shy;ZH
                            </BreadCrumbActive>
                        </>
                        :
                        <>
                            <HeaderLinkInternal onClick={() => navigate("/")} marginLeft={-1} padding={1}>
                                <StyledIcon isActive={false}/>
                                {showEllipsis ?
                                    <BreadCrumbInactive>...</BreadCrumbInactive>
                                    :
                                    <BreadCrumbInactive>Referenz&shy;prozess&shy;ZH</BreadCrumbInactive>}
                            </HeaderLinkInternal>
                        </>
                    }
                    {!onHomePage && !onImpressum &&
                        <>
                            <StyledIcon isActive={true}/>
                            <BreadCrumbActive>
                                Planung
                            </BreadCrumbActive>
                        </>
                    }
                </HeaderTextBox>
            </BreadCrumbToolbar>
        </AppBar>
    )
}

const BreadCrumbToolbar = styled(Toolbar)(({theme}) => ({
    paddingLeft: 0,
    [`@media (min-width:${theme.breakpoints.values.xxs}px)`]: {
        maxHeight: "24px",
        minHeight: "24px",
    },
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        maxHeight: "26px",
        minHeight: "26px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        maxHeight: "30px",
        minHeight: "30px",
    },
    "& > * > *": {
        marginBottom: 0
    },
    "& > * > * > *": {
        marginBottom: 0
    },
}))

const TitleBox = styled(Box)({
    display: "flex",
    alignItems: "center"
})

const StyledIcon = styled(({isActive, ...rest}) => <ArrowRight {...rest}/>)(({isActive, theme}: {
    isActive: boolean,
    theme: Theme
}) => ({
    width: "40px",
    height: "40px",
    "& > *": {
        fill: isActive ? theme.palette.white70.main : theme.palette.white100.main,
    }
}))

const HeaderLinkInternal = styled(Box)(({theme}) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    "&:hover > *": {
        color: theme.palette.white70.main,
    },
    "&:hover > * > *": {
        fill: theme.palette.white70.main,
    },
}))

const HeaderLinkExternal = styled(({...rest}) => <LinkContact {...rest}/>)(({theme}) => ({
    color: theme.palette.white100.main,
    textDecoration: "none",
    flexGrow: 1,
    textAlign: "center",
    fontSize: 22,
    lineHeight: "24px",
}))

const HeaderIconLink = styled(Link)({
    display: "flex",
    padding: "8px",
    marginLeft: "-8px"
})

const StyledPublicContainer = styled(({...rest}) => <Container maxWidth={'xl'} {...rest}/>)(({theme}) => ({
    display: "block",
    padding: 0,
    '@media print': {
        display: "none",
    },
    marginRight: "8px",
    [`@media (min-width:${theme.breakpoints.values.sm}px)`]: {
        marginRight: "16px",
    },
    [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
        marginRight: "20px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        marginRight: "24px",
    }
}))

const HeaderContainer = styled(({isAdmin, ...rest}) => <Container maxWidth={'xl'} {...rest}/>)(({isAdmin, theme}: {
    theme: Theme,
    isAdmin?: boolean
}) => ({
    display: "flex",
    '@media print': {
        display: "none",
    },
    ...(Boolean(isAdmin) ? {} : {
        paddingTop: 24,
        paddingBottom: 24,
        [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
            paddingTop: 40,
            paddingBottom: 40,
        },
    })
}))

const AppBar = styled(MuiAppBar)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    border: "none",
}))

const HeaderTextBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
})

const HeaderMaxWidthContainer = styled(MaxWidthContainer)(({theme}) => ({
    backgroundColor: theme.palette.primary.main
}))

const StyledArrowLeft = styled(ArrowLeft)(({theme}) => ({
    height: "17px",
    width: "17px",
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        height: "19px",
        width: "19px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        height: "20px",
        width: "20px",
    },
    marginRight: "2px",
    "& > *": {
        fill: theme.palette.white100.main,
    },
    "&:hover > *": {
        fill: theme.palette.white70.main,
    },
}))

const BackHeaderLinkExternal = styled(HeaderLinkExternal)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    height: "fit-content",
    flexGrow: 0,

    fill: theme.palette.white100.main,
    color: theme.palette.white100.main,
    "&:hover > *": {
        color: theme.palette.white70.main,
    },
    "&:hover > * > *": {
        fill: theme.palette.white70.main,
    },
}))

const BackHeaderLinkInternal = styled(HeaderLinkInternal)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    width: "max-content",
    height: "max-content",
    lineHeight: "100px",

    fill: theme.palette.white100.main,
    color: theme.palette.white100.main,
    "&:hover > *": {
        fill: theme.palette.white70.main,
        color: theme.palette.white70.main,
    },
}))


export function AdminAppBar() {
    const location = useLocation()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<Element>()
    const {resetRateLimits, publishReferenceProcess} = useAdminApi()
    const selectedTab = detectAdminTab(location.pathname)
    const changeTab = (newValue: number) => {
        if (newValue !== selectedTab) {
            switch (newValue) {
                case 0:
                    navigate(routes.admin.functions.list(0))
                    break;
                case 1:
                    navigate(routes.admin.resources())
                    break;
            }
        }
    }

    return (
        <HeaderContainer disableGutters={true} isAdmin>
            <AppBar position="static" variant="outlined">
                <Toolbar>
                    <Box marginLeft={-1} padding={1}><StyledLogo/></Box>
                    <Typography variant="h6" style={{flexGrow: 1}}>
                        ReferenzprozessZH
                    </Typography>
                    <IconButton aria-label="more actions" edge="end" color="inherit"
                                onClick={e => setAnchorEl(e.currentTarget)}>
                        <Icon>more_vert</Icon>
                    </IconButton>
                    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl}
                          onClose={() => setAnchorEl(undefined)}>
                        <MenuItem onClick={() => {
                            publishReferenceProcess().then(() => {
                                setAnchorEl(undefined)
                            })
                        }}>Aktuelle Version veröffentlichen</MenuItem>
                        <MenuItem onClick={() => window.location.href = routes.admin.exportOverview()}>CSV
                            Export</MenuItem>
                        <MenuItem onClick={() => {
                            resetRateLimits()
                            setAnchorEl(undefined)
                        }}>Zugriffslimit zurücksetzen</MenuItem>
                    </Menu>
                </Toolbar>
                <Tabs value={selectedTab}
                      onChange={(_, newValue) => changeTab(newValue)}
                      centered>
                    <Tab value={0} label="Referenzprozess"/>
                    <Tab value={1} label="Textbausteine"/>
                </Tabs>
            </AppBar>
        </HeaderContainer>
    )
}

const StyledLogo = styled(Logo)({
    verticalAlign: 'top',
})

function detectAdminTab(pathname: string) {
    return pathname.includes(routes.admin.functions.root())
        ? 0
        : pathname.includes(routes.admin.instances())
            ? 1
            : 2;
}