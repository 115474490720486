import React, { useContext } from "react";
import { useRemoteResources } from "../api/PublicHooks";
import { mergeRemoteResources, Resources } from "../api/Resources";

interface ResourceContextType {
    resources: Resources,
    refresh: () => void,
}

const ResourceContext = React.createContext<ResourceContextType>({
    resources: mergeRemoteResources(),
    refresh: () => {
    },
})

export function useResources(): ResourceContextType {
    return useContext(ResourceContext)
}

export function ResourceProvider(props: React.PropsWithChildren<{}>) {
    const {resources, refresh} = useRemoteResources()
    return <ResourceContext.Provider value={{
        resources: mergeRemoteResources(resources),
        refresh
    }}>
        {props.children}
    </ResourceContext.Provider>
}