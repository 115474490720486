import React, { useState } from "react";
import { gutterWidth } from "../../utils/layout";
import { DialogHeader, DialogIconBox, StyledDialogActions, StyledDialogTitle } from "../Dialog/PersonalLinkDialog";
import { ReactComponent as CloseButton } from "../../images/icons/close.svg";
import {
    Button as MuiButton,
    Dialog, DialogContent, DialogContentText,
    Icon,
    styled,
    Toolbar, Typography,
} from "@material-ui/core";
import { ButtonLGPrimary, ButtonLGSecondary } from "../Styleguide/Button";

interface Props {
    outdated: boolean,
    upgrade: () => void,
}

export function InstanceBar({outdated, upgrade}: Props) {
    const [dialogOpen, setDialogOpen] = useState(false)
    const closeDialog = () => setDialogOpen(false);

    return <>
        <Container>
            <ColoredToolbar variant="dense">
                {outdated &&
                <Button color="secondary" startIcon={<Icon>update</Icon>} onClick={() => setDialogOpen(true)}>
                    <Typography variant={"body1"} style={{ marginBottom: 0, color: "black" }}>
                        Update
                    </Typography>
                </Button>}
            </ColoredToolbar>
        </Container>
        <InstanceUpdateDialog open={dialogOpen}
                              handleClose={closeDialog}
                              upgrade={upgrade}/>
    </>
}

function InstanceUpdateDialog(props: { open: boolean, handleClose: () => void, upgrade: () => void }) {
    const updateInstance = () => {
        props.upgrade()
        props.handleClose()
    }
    return <Dialog open={props.open} onClose={props.handleClose}>
        <DialogHeader>
            <StyledDialogTitle>Projekt Aktualisieren</StyledDialogTitle>
            <DialogIconBox onClick={props.handleClose}>
                <CloseButton />
            </DialogIconBox>
        </DialogHeader>
        <DialogContent>
            <DialogContentText>
                Eine neuere Version des Referenzprozesses ist verfügbar.
                Möchten Sie Ihr Projekt auf die neueste Version aktualisieren?
            </DialogContentText>
        </DialogContent>
        <StyledDialogActions>
            <div>
                <ButtonLGSecondary onClick={props.handleClose}>Abbrechen</ButtonLGSecondary>
                <ButtonLGPrimary onClick={updateInstance}>Aktualisiern</ButtonLGPrimary>
            </div>
        </StyledDialogActions>
    </Dialog>
}

const Container = styled('div')(({theme}) => ({
    '@media print': {
        display: "none",
    },
    [`@media (min-width:${theme.breakpoints.values.layout}px)`]: {
        marginLeft: -gutterWidth,
        marginRight: -gutterWidth,
    },
    [`@media (max-width:${theme.breakpoints.values.layout - 1}px)`]: {
        marginLeft: -gutterWidth / 2,
        marginRight: -gutterWidth / 2,
    }
}))

const ColoredToolbar = styled(Toolbar)({backgroundColor: "#FFFFFF"})

const Button = styled(MuiButton)(({theme}) => ({
    position: 'absolute',
    right: theme.spacing(2),
}))