import { styled } from "@material-ui/core";

export const Actions = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    width: "100%",
    [`@media (min-width:${theme.breakpoints.values.landingTable}px)`]: {
        gridTemplateColumns: "1fr 1fr",
        gridTemplateAreas: `"text image"`,
    },
    [`@media (max-width:${theme.breakpoints.values.landingTable - 1}px)`]: {
        gridTemplateRows: "1fr max-width",
        gridTemplateAreas: `"image" "text"`,
    },
}))

export const ActionContainer = styled('div')(({theme}) => ({
    padding: theme.spacing(3),
    backgroundColor: "white",
}))
