import React from "react";
import ReactMarkdown from "react-markdown";
import { styled, Typography } from "@material-ui/core";

const renderers = {
    p: (props: { children: React.ReactNode }) => <Typography variant="body2">{props.children}</Typography>
}

const StyledMarkdown = styled(ReactMarkdown)(({theme}) => ({
    '& > ul': {
        paddingLeft: theme.spacing(3),
        listStylePosition: 'outside',
    },
    wordBreak: "break-word"
}))

interface Props {
    children?: string,
}

export function Markdown(props: Props) {
    return <StyledMarkdown unwrapDisallowed
                           allowedElements={["ul", "ol", "li", "strong", "em", "p"]}
                           components={renderers}>{props.children ?? ""}</StyledMarkdown>
}