import { Link, styled } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

export const LinkPrimary = styled(Link)(({theme}) => ({
    color: theme.palette.black60.main,
    textDecoration: "underline",
    textDecorationColor: theme.palette.zueriBlue.main,

    "&:hover": {
        color: theme.palette.black100.main,
        textDecoration: "underline",
        textDecorationColor: theme.palette.black100.main,
    },
    "&:focus": {
        borderColor: theme.palette.zueriBlue.main,
        textDecoration: "none",
        borderWidth: "2px",
        borderRadius: "1px",
        borderStyle: "solid",
    },
}))
export const LinkNegative = styled(Link)(({theme}) => ({
    color: theme.palette.white100.main,
    textDecoration: "underline",
    textDecorationColor: theme.palette.white100.main,
    "&:hover": {
        color: theme.palette.white70.main,
    },
    "&:focus": {
        borderColor: theme.palette.white100.main,
        textDecoration: "none",
        borderWidth: "1px",
        borderRadius: "3px",
        borderStyle: "solid",
    },
}))
export const LinkContact = styled(Link)(({theme}) => ({
    color: theme.palette.contactGrey.main,
    textDecoration: "none",
    "&:hover": {
        color: theme.palette.white100.main,
    }
}))
export const LinkRouter = styled(RouterLink)(({theme}) => ({
    color: theme.palette.contactGrey.main,
    textDecoration: 'none',
    "&:hover": {
        color: theme.palette.white100.main,
    }
}))