import React, { useEffect, useRef, useState } from "react";
import { steps } from "../../utils/steps";
import { Box, styled, Theme, Typography, useTheme } from "@material-ui/core";
import { BusinessFunction, Comments, Snapshot } from "../../api/Model";
import { ColorBoxProps, FunctionHeader } from "./FunctionHeader";
import { FunctionRecommendation } from "./FunctionRecommendation";
import { LinkPrimary } from "../Styleguide/Link";

const spacingY = 0.75

interface Props {
    snapshot: Snapshot,
    selectedFunctions: string[],
    comments: Comments,
}

export function SummaryContent({snapshot, selectedFunctions, comments}: Props) {
    const selection = {
        functions: snapshot.process.functions.filter(fun => selectedFunctions.includes(fun.id))
            .map(fun => ({
                ...fun,
                comment: comments[fun.id]
            }))
    }
    return <>
        {[0, 1, 2, 3, 4].map(step => <React.Fragment key={step}>
            <AvoidPageBreak>
                <Typography variant={"h2"}>Leistung {steps[step].toLowerCase()}</Typography>
                <ShowFunctions functions={selection.functions.filter(fun => fun.step === step)}/>
            </AvoidPageBreak>
        </React.Fragment>)}
        <ShowInformation />
    </>
}

function ShowFunctions(props: { functions: (BusinessFunction & { comment: string })[] }) {
    if (props.functions.length) {
        return <>
            {props.functions
                .map((fun, i) => <ShowBusinessFunction key={fun.id} fun={fun} lastElement={i === props.functions.length - 1} comment={fun.comment}/>)}
        </>
    } else {
        return <Typography variant={"body1"}>Keine Funktionen ausgewählt</Typography>
    }
}

function ShowBusinessFunction(props: { fun: BusinessFunction, lastElement: boolean, comment?: string }) {
    const customerIsEmpty = props.fun.customer.name.length === 0;
    const administrationIsEmpty = props.fun.administration.name.length === 0;
    const hasCustomerRecommendation = props.fun.customer.recommendation !== null && props.fun.customer.recommendation.length > 0
    const hasAdministrationRecommendation = props.fun.administration.recommendation !== null && props.fun.administration.recommendation.length > 0

    const leftRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (leftRef && leftRef.current && rightRef && rightRef.current) {
            const leftSize = leftRef.current.clientHeight;
            const rightSize = rightRef.current.clientHeight;
            if (leftSize > rightSize) {
                leftRef.current.style.height = `${leftSize}px`;
                rightRef.current.style.height = `${leftSize}px`;
            }
            else if (rightSize > leftSize) {
                leftRef.current.style.height = `${rightSize}px`;
                rightRef.current.style.height = `${rightSize}px`;
            }
        }
    }, [])

    return (
        <NoBreakInsideBox marginBottom={3} lastElement={props.lastElement}>
            <FunctionDataContainer empty={customerIsEmpty}>
                <FunctionHeader functionData={props.fun.customer}
                                type={"customer"} ref={leftRef}/>
                {hasCustomerRecommendation && <FunctionRecommendation functionData={props.fun.customer}/>}
            </FunctionDataContainer>
            <FunctionDataContainer empty={administrationIsEmpty}>
                <FunctionHeader functionData={props.fun.administration}
                                type={"administration"} ref={rightRef}/>
                {hasAdministrationRecommendation && <FunctionRecommendation functionData={props.fun.administration}/>}
            </FunctionDataContainer>
            {props.comment && <BorderlessTextField label="Bemerkungen" value={props.comment}/>}
        </NoBreakInsideBox>
    )
}

function ShowInformation() {
    const theme = useTheme();

    return (
        <InformationNoBreakInsideBox marginBottom={3}>
            <InformationColorBox backgroundColor={theme.palette.white100.main} empty={true} />
            <InformationBox backgroundColor={theme.palette.black10.main} empty={false}>
                <Typography variant={"body2"}>
                    Noch Fragen?
                </Typography>
                <Typography variant={"body1"}>
                    Unser <LinkPrimary href="https://www.zh.ch/de/staatskanzlei/digitale-verwaltung/team.html#-2078353175" target="_blank" rel="noopener">Team Leistungen</LinkPrimary> der Staatskanzlei Zürich unterstützt gern bei der Umsetzung Ihres digitalen Vorhabens innerhalb der Verwaltung.
                </Typography>
            </InformationBox>
        </InformationNoBreakInsideBox>
    )
}

const InformationColorBox = styled(({backgroundColor, empty, ...rest}) => <Box {...rest}/>)
((props: { theme: Theme } & ColorBoxProps) => ({
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    textAlign: "center",
    padding: props.empty ? 0 : props.theme.spacing(spacingY, 0),
    backgroundColor: props.empty ? undefined : props.backgroundColor,
    '@media print': {
        boxShadow: `inset 0 0 0 1000px ${props.backgroundColor}`,
    }
}))

interface TextFieldProps {
    label: string,
    value: string
}

function BorderlessTextField(props: TextFieldProps) {
    const theme = useTheme();

    return <fieldset style={{margin: 0, paddingLeft: 16, paddingRight: 16, border: 0, color: theme.palette.black20.main}}>
        <legend style={{fontSize: '0.75rem', padding: 0}}>{props.label}</legend>
        {props.value}
    </fieldset>
}

const FunctionDataContainer = styled(({theme, empty, ...rest}) => <Box {...rest}/>)
(({theme, empty} : { theme: Theme, empty: Boolean }) => ({
    maxWidth: "50%",
    minWidth: "300px",
    flexGrow: 1,
    flexShrink: 1,
    height: empty ? 0 : 'auto',
    [`@media (max-width: ${theme.breakpoints.values.summaryLayout - 1}px)`]: {
        maxWidth: "100%"
    }
}))

const NoBreakInsideBox = styled(({theme, lastElement, ...rest}) => <Box {...rest}/>)(({lastElement, theme} : { lastElement: Boolean, theme: Theme }) => ({
    pageBreakInside: "avoid",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    paddingBottom: lastElement ? "0px" : "16px",
    marginBottom: "16px",
    borderBottom: lastElement ? undefined : `1px ${theme.palette.borderGrey.main} solid`,
}))

const AvoidPageBreak = styled('div')({
    pageBreakAfter: "avoid"
})

const InformationBox = styled(InformationColorBox)({
    flexDirection: "column",
    paddingLeft: "6px",
    paddingRight: "6px",
    alignItems: "flex-start",
    textAlign: "left",
    '@media print': {
        display: "none",
    },
    "& > *": {
        marginBlockStart: "0.5em",
        marginBlockEnd: "0.5em",
        marginLeft: "0.5em"
    }
})

const InformationNoBreakInsideBox = styled(Box)({
    pageBreakInside: "avoid",
})
