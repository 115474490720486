import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';

export interface UserDetails {
    name: string
}

interface LoginActions {
    login: (username: string, password: string) => Promise<boolean>,
    logout: () => void,
}

interface LoginState {
    loggedIn?: boolean
}

const LoginActionsContext = React.createContext<LoginActions | undefined>(undefined)

const LoginStateContext = React.createContext<LoginState>({})

interface Props {
    children: React.ReactNode
}

export function useLoginState(): LoginState {
    return useContext(LoginStateContext)
}

export function useLoginActions(): LoginActions {
    return useContext(LoginActionsContext)!
}

export function LoginProvider(props: Props) {
    const [state, setState] = useState<LoginState>({})
    const login = (username: string, password: string) => {
        return axios.get<UserDetails>("/api/v1/private/user-info", {
            auth: {username, password}
        })
            .then(() => {
                setState({loggedIn: true})
                return Promise.resolve(true)
            })
            .catch(() => {
                setState({loggedIn: false})
                return Promise.resolve(false)
            })
    }
    const logout = () => setState({loggedIn: false})
    useEffect(() => {
        const source = axios.CancelToken.source();
        axios.get<UserDetails>("/api/v1/private/user-info", {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            cancelToken: source.token
        })
            .then(() => {
                setState({loggedIn: true})
                return Promise.resolve(true)
            })
            .catch(() => {
                setState({loggedIn: false})
                return Promise.resolve(false)
            })
        return () => source.cancel()
    }, [])
    return <LoginActionsContext.Provider value={{login, logout}}>
        <LoginStateContext.Provider value={state}>
            {props.children}
        </LoginStateContext.Provider>
    </LoginActionsContext.Provider>
}